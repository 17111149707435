'use client';

import { Alert, Box, Button, Stack } from '@mui/material';
import { signIn } from 'next-auth/react';
import BaseCard from '@cp/ui/components/card/BaseCard';
import { AuthErrors } from '@cp/shared/lib/error';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import withSuspense from '@cp/ui/components/WithSuspense';

const ProviderSignInPage = () => {
  const searchParams = useSearchParams();

  const error = searchParams?.get('error');

  const handleGoogleSignin = async () => {
    await signIn('google', {
      callbackUrl: '/dashboard',
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      paddingBottom="25%"
    >
      <BaseCard width="512px">
        <Stack spacing={4}>
          {error && (
            <Alert severity="error" sx={{ marginTop: '1em' }}>
              {AuthErrors[error]}
            </Alert>
          )}
          <Box display="flex" justifyContent="center">
            <Image
              src="/assets/clarity-logo.svg"
              alt="Clarity Pediatrics logo"
              placeholder="blur"
              blurDataURL={'/assets/clarity-logo.svg'}
              width="0"
              height="0"
              style={{ width: '50%', height: 'auto' }}
              sizes="100vw"
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleGoogleSignin}
            startIcon={
              <Image
                src="https://authjs.dev/img/providers/google.svg"
                alt="google"
                width={32}
                height={32}
              />
            }
            size="large"
          >
            Sign in with Google
          </Button>
        </Stack>
      </BaseCard>
    </Box>
  );
};

export default withSuspense(ProviderSignInPage);
